import React, { useContext } from 'react';
import { Card, Button } from 'react-bootstrap';

import { ListContext } from '../contexts/ListProvider';

import { formatCurrency, formatToDecimal } from '../libs/utils';

import Icon from '../ui/Icon';

function ItemBox({ item }) {

    const { setShowModalReserveItem, setShowedItem } = useContext(ListContext);

    return (
        <>
            <Card className='mb-4'>
                <div className='ratio ratio-1x1 bg-white'>
                    {item.image && <Card.Img variant="top" src={item.image} className="p-3" style={{ objectFit: "cover" }} />}
                </div>

                <Card.Body>
                    <Card.Title className="text-primary-dark2 mb-3">{item.emoji} {item.name}</Card.Title>
                    <Card.Subtitle className='mb-2'>{item.brand ? item.brand : ''}<br />{item.model ? <span style={{ fontWeight: 200 }}>{item.model}</span> : <br />}</Card.Subtitle>
                    <Card.Text className="text-primary-light">
                        {item.is_reserved === '0' ?
                            item.sponsoring_type !== "contribute" ?
                                formatCurrency(item.price) : <span>Encore {formatCurrency(formatToDecimal(item.price - item.sponsoring_amount))}</span> : <span>&nbsp;</span>}
                    </Card.Text>
                    {item.is_reserved === '0' && <Button variant="primary" onClick={() => { setShowedItem(item.id); setShowModalReserveItem(true); }}><Icon name="hands-holding-heart" iconStyle="light" className="me-2" />Parrainer</Button>}
                    {item.is_reserved === '1' && <Card.Text style={{ height: 38, lineHeight: '38px' }} className="text-primary-dark2"><Icon name="heart" iconStyle="light" className="me-2" />Parrainé</Card.Text>}
                </Card.Body>
            </Card>
        </>
    )
}

export default ItemBox;
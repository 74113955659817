import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Row, Col, Tooltip } from 'react-bootstrap';

import { fetchBirthlist, createItemSponsoring } from '../services/birthlists';

import { ListContext } from '../contexts/ListProvider';

import Icon from '../ui/Icon';
import { formatCurrency, formatToDecimal } from '../libs/utils';
import UITooltip from '../ui/UITooltip';

const thanksGifs = [
    'https://media.giphy.com/media/VNo2uNMv7w9MI/giphy.gif',
    'https://media.giphy.com/media/L4nhnVkCXw5Da/giphy.gif',
    'https://media.giphy.com/media/xUA7b1wBLboNDHIGvC/giphy.gif',
    'https://media.giphy.com/media/ujmbr3Va4hMSex7Jui/giphy.gif',
    'https://media.giphy.com/media/jF9gFVcrNoN7q/giphy.gif',
    'https://media.giphy.com/media/h96hGvhcUekdW/giphy.gif',
]

function ModalReserveItem() {

    const { items, setItems, showedItem, showModalReserveItem, setShowModalReserveItem } = useContext(ListContext);

    const [modalTitle, setModalTitle] = useState('');
    const [modalEmoji, setModalEmoji] = useState('');
    const [modalItem, setModalItem] = useState({});

    const [modalStep, setModalStep] = useState(1);

    const [itemSponsoring, setItemSponsoring] = useState({
        sponsoring_name: '',
        sponsoring_email: '',
        sponsoring_message: '',
        sponsoring_type: 'reserve'
    });

    const [invalidName, setInvalidName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidSponsoringAmount, setInvalidSponsoringAmount] = useState(false);

    const [errorName, setErrorName] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorSponsoringAmount, setErrorSponsoringAmount] = useState(null);

    const [thanksGif, setThanksGif] = useState(thanksGifs[Math.floor(Math.random() * thanksGifs.length)]);

    useEffect(() => {
        if (showedItem && showModalReserveItem) {
            setThanksGif(thanksGifs[Math.floor(Math.random() * thanksGifs.length)]);
            const item = items.filter((a_item) => a_item.id === showedItem).shift();
            setModalItem(item);
            setItemSponsoring({
                sponsoring_name: '',
                sponsoring_email: '',
                sponsoring_message: '',
                sponsoring_type: item.sponsoring_type === 'contribute' ? 'contribute' : item.is_purchased === '0' ? 'reserve' : 'pay',
                sponsoring_amount: 0.00
            });
            let title = '';
            title += 'Parrainer '
            title += (item.is_plural === "1") ? ' les ' : (item.gender === "m") ? ' le ' : ' la ';
            title += item.name.toLowerCase();
            title += ' de Louise';
            setModalTitle(title);
            setModalEmoji(item.emoji);
        }

    }, [showModalReserveItem]);

    const handleClose = () => {
        setShowModalReserveItem(false);
        setModalStep(1);
        setInvalidName(false);
        setInvalidEmail(false);
        setInvalidSponsoringAmount(false);
        setErrorName(null);
        setErrorEmail(null);
        setErrorSponsoringAmount(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let hasErrors = false;
        if (itemSponsoring.sponsoring_name === '') {
            setInvalidName(true);
            setErrorName("empty")
            hasErrors = true;
        }
        else {
            setInvalidName(false);
            setErrorName(null);
        }
        if (itemSponsoring.sponsoring_email === '') {
            setInvalidEmail(true);
            setErrorEmail("empty");
            hasErrors = true;
        }
        else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(itemSponsoring.sponsoring_email)) {
            setInvalidEmail(true);
            setErrorEmail("format");
            hasErrors = true;
        }
        else {
            setInvalidEmail(false);
            setErrorEmail(null);
        }
        if (itemSponsoring.sponsoring_type !== "contribute") {
            itemSponsoring.sponsoring_amount = modalItem.price;
        }

        itemSponsoring.sponsoring_amount = itemSponsoring.sponsoring_amount.replace(",", ".");

        if (!hasErrors) {
            createItemSponsoring(showedItem, itemSponsoring).then((response) => {
                if (response.status === "Created") {
                    console.info("✅ Sponsoring created");
                    setModalStep(4);
                    fetchBirthlist('1').then((response) => {
                        console.log(response);
                        setItems(response.result.items);
                    });
                }
            });
        }
    };

    const handleSubmitType = (e) => {
        e.preventDefault();
        let hasErrors = false,
            regExp = /^\d+(\.\d{1,2})?$/

        console.log('sponsoring_type', itemSponsoring.sponsoring_type);
        console.log('sponsoring_amount', itemSponsoring.sponsoring_amount);


        if (itemSponsoring.sponsoring_type === "contribute") {
            console.log(itemSponsoring.sponsoring_amount);
            let sponsoring_amount = itemSponsoring.sponsoring_amount;
            if (sponsoring_amount) {
                sponsoring_amount = itemSponsoring.sponsoring_amount.replace(",", ".");
            }
            if (sponsoring_amount == 0) {
                setInvalidSponsoringAmount(true);
                setErrorSponsoringAmount("empty")
                hasErrors = true;
                console.log("ok");
            }
            else if (!regExp.test(sponsoring_amount)) {
                setInvalidSponsoringAmount(true);
                setErrorSponsoringAmount("format");
                hasErrors = true;
            }
            else if (sponsoring_amount > (modalItem.price - modalItem.sponsoring_amount)) {
                setInvalidSponsoringAmount(true);
                setErrorSponsoringAmount("too_high")
                hasErrors = true;
            }
            else {
                setInvalidSponsoringAmount(false);
                setErrorSponsoringAmount(null);
            }
        }
        else {
            setInvalidSponsoringAmount(false);
            setErrorSponsoringAmount(null);
        }

        if (!hasErrors) {
            setModalStep(3);
        }
    };

    let modalContent = "";

    switch (modalStep) {
        case 1:
            modalContent = (<>
                <Modal.Body>
                    {/* <p className='lead text-primary-dark2 fw-500 mb-2'>{modalItem.emoji} {modalItem.name}</p> */}
                    <p className='lead fw-500 mb-2'>{modalItem.brand ? modalItem.brand : ''}<br />{modalItem.model ? <span style={{ fontWeight: 200 }}>{modalItem.model}</span> : ''} </p>
                    <p className='lead text-primary-light mb-0'>{formatCurrency(modalItem.price)}</p>
                    {modalItem.text && (<>
                        <p className='lead text-primary-dark2 fw-500 mb-0 mt-2'>Le choix des parents</p>
                        <p className='mb-0'><Icon name="quote-left" className="me-2 text-primary-dark2" />{modalItem.text}<Icon name="quote-right" className="ms-2 text-primary-dark2" /></p>
                    </>)}
                    {modalItem.is_purchased === '1' && (<>
                        <p className='mt-3 mb-0 fw-500'><Icon name="triangle-exclamation" className="me-2" />Nous avons déjà acheté cet objet,<br />mais vous pouvez quand même le parrainer via une contribution.</p>
                    </>)}
                    {modalItem.is_purchased === '0' && modalItem.sponsoring_type !== 'contribute' && (<>
                        <p className='mt-3 mb-3 fw-500'>Nous n'avons pas encore acheté cet objet,<br />vous pouvez le réserver pour nous le faire parvenir vous même<br />ou le parrainer via une contribution.</p>
                        <p className='mb-0 fw-500 lead'><a href={modalItem.link} className="text-primary-dark2" target="_blank">Cliquez-ici pour aller sur la page du produit en ligne</a></p>
                    </>)}
                    {modalItem.sponsoring_type === 'contribute' && (<>
                        <p className='mt-3 mb-2 fw-500'><Icon name="triangle-exclamation" className="me-2" />Des personnes ont déja parrainé une partie de cet objet,<br />mais vous pouvez le parrainer en contribuant au montant restant.</p>
                        <p className='mb-1 text-primary-light'>Montant déjà parrainé : {formatCurrency(modalItem.sponsoring_amount)}</p>
                        <p className='text-primary-dark2 fw-500 mb-0'>Montant restant : {formatCurrency(formatToDecimal(modalItem.price - modalItem.sponsoring_amount))}</p>
                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" size="lg" onClick={() => setModalStep(2)}>
                        Continuer<Icon name="arrow-right" iconStyle="light" className="ms-2" fixedWidth />
                    </Button>
                </Modal.Footer>
            </>);
            break;

        case 2:
            modalContent = (<>
                <Form onSubmit={(e) => handleSubmitType(e)} noValidate>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label className='text-primary-dark2'>Comment voulez-vous parrainer cet objet ?</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3 text-start group-choice">
                            {modalItem.is_purchased === '1' && modalItem.sponsoring_type !== "contribute" &&
                                <UITooltip content="Déjà acheté" placement="left">
                                    <Form.Check
                                        type='radio'
                                        name="choice"
                                        value="reserve"
                                        checked={itemSponsoring.sponsoring_type === 'reserve'}
                                        label={`En reservant l'objet pour nous le faire parvenir plus tard`}
                                        disabled={modalItem.sponsoring_type === "contribute" || modalItem.is_purchased === '1'}
                                        onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'reserve' })}
                                    />
                                </UITooltip>
                            }
                            {modalItem.sponsoring_type === "contribute" &&
                                <UITooltip content="Déjà parrainé en partie" placement="left">
                                    <Form.Check
                                        type='radio'
                                        name="choice"
                                        value="reserve"
                                        checked={itemSponsoring.sponsoring_type === 'reserve'}
                                        label={`En reservant l'objet pour nous le faire parvenir plus tard`}
                                        disabled={modalItem.sponsoring_type === "contribute" || modalItem.is_purchased === '1'}
                                        onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'reserve' })}
                                    />
                                </UITooltip>
                            }
                            {modalItem.is_purchased === '0' && modalItem.sponsoring_type !== "contribute" &&
                                <Form.Check
                                    type='radio'
                                    name="choice"
                                    value="reserve"
                                    checked={itemSponsoring.sponsoring_type === 'reserve'}
                                    label={`En reservant l'objet pour nous le faire parvenir plus tard`}
                                    disabled={modalItem.sponsoring_type === "contribute" || modalItem.is_purchased === '1'}
                                    onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'reserve' })}
                                />
                            }
                            {modalItem.sponsoring_type === "contribute" &&
                                <UITooltip content="Déjà parrainé en partie" placement="left">
                                    <Form.Check
                                        type='radio'
                                        name="choice"
                                        value="pay"
                                        checked={itemSponsoring.sponsoring_type === 'pay'}
                                        disabled={modalItem.sponsoring_type === "contribute"}
                                        label={`En versant la totalité de la valeur de l'objet dans le pot commun`}
                                        onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'pay' })}
                                    />
                                </UITooltip>
                            }
                            {modalItem.sponsoring_type !== "contribute" &&
                                <Form.Check
                                    type='radio'
                                    name="choice"
                                    value="pay"
                                    checked={itemSponsoring.sponsoring_type === 'pay'}
                                    disabled={modalItem.sponsoring_type === "contribute"}
                                    label={`En versant la totalité de la valeur de l'objet dans le pot commun`}
                                    onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'pay' })}
                                />
                            }
                            <Form.Check
                                type='radio'
                                name="choice"
                                value="contribute"
                                checked={itemSponsoring.sponsoring_type === 'contribute' || modalItem.sponsoring_type === "contribute"}
                                label={`En contribuant à une partie de la valeur de l'objet dans le pot commun`}
                                onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'contribute' })}
                            />
                        </Form.Group>
                        {(itemSponsoring.sponsoring_type === 'contribute' || modalItem.sponsoring_type === "contribute") &&
                            <Form.Group className="mb-3 group-choice">
                                <Form.Control
                                    type="number"
                                    placeholder="Entrez le montant de votre contribution"
                                    min="0.01" max={modalItem.price - modalItem.sponsoring_amount} step="0.01"
                                    onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_amount: e.target.value.replace(".", ",") })}
                                    value={itemSponsoring.sponsoring_amount}
                                    isInvalid={invalidSponsoringAmount}
                                />
                                {errorSponsoringAmount && (<Form.Control.Feedback type="invalid">
                                    {errorSponsoringAmount === "empty" && 'Veuillez entrez un montant.'}
                                    {errorSponsoringAmount === "format" && 'Veuillez entrez un montant valide.'}
                                    {errorSponsoringAmount === "too_high" && `Le montant maximum est de ${formatToDecimal(modalItem.price - modalItem.sponsoring_amount).replace(".", ",")}€.`}
                                </Form.Control.Feedback>)}
                            </Form.Group>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" variant="light" size="lg" className='me-auto' onClick={() => setModalStep(1)}>
                            <Icon name="arrow-left" iconStyle="light" className="me-2" fixedWidth />Retour
                        </Button>
                        <Button type="submit" variant="primary" size="lg">
                            Continuer<Icon name="arrow-right" iconStyle="light" className="ms-2" fixedWidth />
                        </Button>
                    </Modal.Footer>
                </Form>
            </>);
            break;

        case 3:
            modalContent = (<>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Control
                                type="text"
                                placeholder="Votre nom"
                                onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_name: e.target.value })}
                                isInvalid={invalidName}
                            />
                            {errorName && (<Form.Control.Feedback type="invalid">
                                {errorName === "empty" && 'Veuillez entrez votre nom.'}
                            </Form.Control.Feedback>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Votre adresse mail"
                                onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_email: e.target.value })}
                                isInvalid={invalidEmail}
                            />
                            {errorEmail && (<Form.Control.Feedback type="invalid">
                                {errorEmail === "empty" && 'Veuillez entrez votre adresse mail.'}
                                {errorEmail === "format" && 'Veuillez entrez une adresse mail valide.'}
                            </Form.Control.Feedback>)}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Votre message"
                                onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_message: e.target.value })}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" variant="light" size="lg" className='me-auto' onClick={() => setModalStep(2)}>
                            <Icon name="arrow-left" iconStyle="light" className="me-2" fixedWidth />Retour
                        </Button>
                        <Button type="submit" variant="primary" size="lg">
                            Parrainer<Icon name="check" iconStyle="light" className="ms-2" fixedWidth />
                        </Button>
                    </Modal.Footer>
                </Form>
            </>);
            break;

        case 4:
            modalContent = (<>
                <Modal.Body>
                    <p className='lead'>Merci infiniment pour votre participation !<br />😘😘😘</p>
                    <div className='mb-3'>
                        <img src={thanksGif} alt="merci" style={{ width: 250 }} />
                    </div>
                    {itemSponsoring.sponsoring_type === "reserve" && (<>
                        <p className="lead"><a href={modalItem.link} className="text-primary-dark2 fw-500" target="_blank">Cliquez ici pour accéder à la page web où acheter l'objet.</a></p>
                        <p>Voici notre adresse pour nous faire parvenir l'objet :<br></br>
                            <address>Famille Vidal-Julien<br />
                                9 rue Telles de la Poterie<br />
                                92130 Issy-Les-Moulineaux</address>
                        </p>
                        <p className='mb-0 lead'><a href="https://lydia-app.com/collect/louise-2023/fr" target="_blank" className="text-primary-dark2 fw-500">Vous pouvez également contribuer à notre cagnotte en cliquant ici.</a></p>
                    </>)}
                    {itemSponsoring.sponsoring_type !== "reserve" && (<>
                        <p className='lead mt-2'><a href="https://lydia-app.com/collect/louise-2023/fr" target="_blank" className="text-primary-dark2 fw-500">Vous pouvez verser votre contribution sur notre cagnotte en cliquant ici.</a></p>
                    </>)}
                </Modal.Body>
                <Modal.Footer className='d-grid'>
                    <Button onClick={handleClose} variant="light" size="lg">
                        Fermer<Icon name="times" iconStyle="solid" className="ms-2" fixedWidth />
                    </Button>
                </Modal.Footer>
            </>);
            break;


        default:
            break;
    }
    //     <>
    //         <Modal.Body>
    //             <p className='lead'>Merci infiniment pour votre participation !<br />😘😘😘</p>
    //             <div className='mb-3'>
    //                 <img src={thanksGif} alt="merci" style={{ width: 250 }} />
    //             </div>

    //             {confirmText}
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <Button onClick={handleClose} variant="light" >
    //                 <Icon name="times" iconStyle="solid" className="me-1" />Fermer
    //             </Button>
    //         </Modal.Footer>
    //     </> : isCreating ? <>

    //         <Form onSubmit={handleSubmit}>
    //             <Modal.Body>
    //                 <Form.Group className="mb-2">
    //                     <Form.Control type="text" placeholder="Votre nom" onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_name: e.target.value })} isInvalid={invalidName} />
    //                 </Form.Group>
    //                 <Form.Group className="mb-3">
    //                     <Form.Control type="text" placeholder="Votre adresse mail" onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_email: e.target.value })} isInvalid={invalidEmail} />
    //                 </Form.Group>
    //                 <Form.Group className="mb-4">
    //                     <Form.Control as="textarea" rows={3} placeholder="Votre message" onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_message: e.target.value })} />
    //                 </Form.Group>
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button type="submit" variant="primary" size="lg">
    //                     <Icon name="check" iconStyle="solid" className="me-1" />Parrainer
    //                 </Button>
    //             </Modal.Footer>
    //         </Form>

    //     </> : <>
    //         <Form onSubmit={handleNextStep}>
    //             <Modal.Body>
    //                 <p className='lead text-primary-dark2'>Le choix des parents</p>
    //                 <p><Icon name="quote-left" className="me-2 text-primary-dark2" />{modalItem.text}<Icon name="quote-right" className="ms-2 text-primary-dark2" /></p>
    //                 <Form.Group className="mb-2">
    //                     <Form.Label>Prix de l'objet à parrainer : <span className='text-primary-dark2'>{formatCurrency(modalItem.price)}</span></Form.Label>
    //                     {modalItem.sponsoring_type === 'contribute' && <><br /><Form.Label>Montant déjà parrainé : <span className='text-primary-dark2'>{formatCurrency(modalItem.sponsoring_amount)}</span></Form.Label></>}
    //                     {modalItem.sponsoring_type === 'contribute' && <><br /><Form.Label>Montant restant à parrainer : <span className='text-primary-dark2'>{formatCurrency(formatToDecimal(modalItem.price - modalItem.sponsoring_amount))}</span></Form.Label></>}
    //                 </Form.Group>
    //                 <Form.Group className="mb-2">
    //                     <Form.Label className='text-primary-dark2'>Comment voulez-vous parrainer cet objet ?</Form.Label>
    //                 </Form.Group>
    //                 <Form.Group className="mb-3 text-start group-choice">
    //                     <Form.Check
    //                         type='radio'
    //                         name="choice"
    //                         value="reserve"
    //                         checked={itemSponsoring.sponsoring_type === 'reserve'}
    //                         label={`En reservant l'objet pour nous le faire parvenir plus tard`}
    //                         disabled={modalItem.sponsoring_type === "contribute" || modalItem.is_purchased === '1'}
    //                         onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'reserve' })}
    //                     />
    //                     <Form.Check
    //                         type='radio'
    //                         name="choice"
    //                         value="pay"
    //                         checked={itemSponsoring.sponsoring_type === 'pay'}
    //                         disabled={modalItem.sponsoring_type === "contribute"}
    //                         label={`En versant la totalité de la valeur de l'objet dans le pot commun`}
    //                         onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'pay' })}
    //                     />
    //                     <Form.Check
    //                         type='radio'
    //                         name="choice"
    //                         value="contribute"
    //                         checked={itemSponsoring.sponsoring_type === 'contribute' || modalItem.sponsoring_type === "contribute"}
    //                         label={`En contribuant à une partie de la valeur de l'objet dans le pot commun`}
    //                         onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_type: 'contribute' })}
    //                     />
    //                 </Form.Group>
    //                 {(itemSponsoring.sponsoring_type === 'contribute' || modalItem.sponsoring_type === "contribute") &&
    //                     <Form.Group className="mb-3 group-choice">
    //                         <Form.Control
    //                             type="number"
    //                             placeholder="Entrez le montant de votre contribution"
    //                             min="0.01" max={modalItem.price - modalItem.sponsoring_amount} step="0.01"
    //                             onChange={(e) => setItemSponsoring({ ...itemSponsoring, sponsoring_amount: e.target.value })}
    //                         />
    //                     </Form.Group>
    //                 }
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button type="submit" variant="primary" size="lg">
    //                     <Icon name="check" iconStyle="solid" className="me-1" />Continuer
    //                 </Button>
    //             </Modal.Footer>
    //         </Form>
    //     </>;

    return (
        <Modal show={showModalReserveItem} onHide={handleClose} size="lg" fullscreen="lg-down" centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-primary-dark2'><span style={{ fontSize: '1.3em', marginRight: '.4em' }}>{modalEmoji}</span>{modalTitle}</Modal.Title>
            </Modal.Header>
            {modalContent}
        </Modal>
    )

}
export default ModalReserveItem;
import { useState, createContext } from "react";

export const ListContext = createContext();

export const ListProvider = ({ children }) => {

  const [items, setItems] = useState([]);
  const [showModalReserveItem, setShowModalReserveItem] = useState(false);
  const [showedItem, setShowedItem] = useState(null);
  const [lastAction, setLastAction] = useState([]);

  return (
    <ListContext.Provider
      value={{
        items, setItems,
        showModalReserveItem, setShowModalReserveItem,
        showedItem, setShowedItem,
        lastAction, setLastAction
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

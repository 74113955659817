const CLOUD_URL = 'hub-clouds.guru-worlds.com';
const PASSPORT_NAME = 'passport_birthlist';

export const uploadFile = async (fileData) => {
    const CLOUD_CALL_URL = CLOUD_URL + '/file';
    const passport = sessionStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    const options = {
        method: 'POST',
        headers: headers,
        body: fileData
    };
    const response = await fetch('https://' + CLOUD_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const deleteFile = async (fileURL) => {
    const CLOUD_CALL_URL = CLOUD_URL + '/file/' + fileURL;
    const passport = sessionStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    const options = {
        method: 'DELETE',
        headers: headers
    };
    const response = await fetch('https://' + CLOUD_CALL_URL, options);
    const data = await response.json();
    return data;
};
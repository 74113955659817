import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";

import { ListContext } from '../contexts/ListProvider';

import { updateItem, createItem, deleteItem, fetchBirthlist } from '../services/birthlists';
import { uploadFile } from '../services/cloud';

import { Row, Col, Form, Button } from 'react-bootstrap';

import Icon from '../ui/Icon';

function ItemForm() {

    const params = useParams();
    const navigate = useNavigate();

    const { items, setItems, lastAction, setLastAction } = useContext(ListContext);

    const [item, setItem] = useState({
        item_name: '',
        item_emoji: '',
        item_gender: '',
        item_brand: '',
        item_model: '',
        item_price: '',
        item_text: '',
        item_image: '',
        item_link: '',
        item_is_plural: '0',
        item_is_purchased: '0',
    });
    const [itemID, setItemID] = useState(null);
    const [mode, setMode] = useState('add');

    useEffect(() => {
        if (items.length) {
            if (params.id) {
                setMode("edit");
                setItemID(params.id);
                const itemData = items.filter((a_item) => a_item.id === params.id).shift();
                console.log(itemData);
                setItem({
                    item_name: itemData.name,
                    item_emoji: itemData.emoji,
                    item_gender: itemData.gender,
                    item_brand: itemData.brand,
                    item_model: itemData.model,
                    item_price: itemData.price,
                    item_text: itemData.text,
                    item_image: itemData.image,
                    item_link: itemData.link,
                    item_is_plural: itemData.is_plural,
                    item_is_purchased: itemData.is_purchased,
                });
            }
            else {
                setMode("add");
                setItemID(null);
                setItem({
                    item_name: '',
                    item_emoji: '',
                    item_gender: '',
                    item_brand: '',
                    item_model: '',
                    item_price: '',
                    item_text: '',
                    item_image: '',
                    item_link: '',
                    item_is_plural: '0',
                    item_is_purchased: '0',
                });
            }
        }
    }, [items]);

    useEffect(() => {
        console.log(item);
    }, [item]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(item);
        let hasErrors = false;

        if (!hasErrors) {
            if (mode === "edit") {
                updateItem(itemID, item)
                    .then((response) => {
                        if (response.status === "Updated") {
                            console.info("✅ Item updated");
                            fetchBirthlist('1').then((response) => {
                                console.log(response);
                                setLastAction([...lastAction, "update_item"]);
                                setItems(response.result.items);
                                navigate("/liste");
                            });
                        }
                    });
            }
            else {
                createItem('1', item)
                    .then((response) => {
                        if (response.status === "Created") {
                            console.info("✅ Item created");
                            fetchBirthlist('1').then((response) => {
                                console.log(response);
                                setLastAction([...lastAction, "create_item"]);
                                setItems(response.result.items);
                                navigate("/liste");
                            });
                        }
                    });
            }

        }
    };

    const handleDelete = () => {
        deleteItem(itemID)
            .then((response) => {
                if (response.status === "Deleted") {
                    console.info("✅ Item deleted");
                    fetchBirthlist('1').then((response) => {
                        console.log(response);
                        setLastAction([...lastAction, "delete_item"]);
                        setItems(response.result.items);
                        navigate("/liste");
                    });
                }
            });
    }

    const inputRef = useRef(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleImageUpload = (event) => {
        event.preventDefault();
        let fileName = "image_birthlist";
        fileName = fileName.replaceAll(" ", "-").toLowerCase();
        const filePath = "birthlist/";
        const fileData = new FormData();
        fileData.append("file", event.target.files[0]);
        fileData.append("file_name", fileName);
        fileData.append("file_path", filePath);
        uploadFile(fileData).then((response) => {
            const fileURL = 'https://' + response.result.url;
            setItem({ ...item, item_image: fileURL })
        });
    }


    return (
        <>
            <h1><Link to="/" className='text-primary-dark2'>Liste de naissance</Link></h1>
            <section className="text-start">
                <h2>{mode === "add" ? "Ajouter" : "Modifier"} un objet</h2>
                <Form className='mt-3' onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-0">
                                {item.item_image !== "" && <>
                                    <div className='mt-2'>
                                        <img src={item.item_image} alt="objet" style={{ maxWidth: '100%' }} className="img-thumbnail shadow-sm" />
                                    </div>
                                </>}
                                <div className="d-grid mt-3">
                                    <Button variant="outline-primary" onClick={handleUpload}>{item.item_image !== "" ? 'Changer' : 'Ajouter'} l'image</Button>
                                    <input ref={inputRef} className="d-none" type="file" onChange={handleImageUpload} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm={9}>
                            <Row>
                                <Col sm={2}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Emoji</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Emoji de l'objet"
                                            value={item.item_emoji}
                                            onChange={(e) => setItem({ ...item, item_emoji: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={10}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nom de l'objet"
                                            value={item.item_name}
                                            onChange={(e) => setItem({ ...item, item_name: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Marque</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Marque de l'objet"
                                            value={item.item_brand}
                                            onChange={(e) => setItem({ ...item, item_brand: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Modèle</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Modèle de l'objet"
                                            value={item.item_model}
                                            onChange={(e) => setItem({ ...item, item_model: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Présentation</Form.Label>
                                        <Form.Control
                                            as="textarea" rows={3}
                                            placeholder="Présentation de l'objet"
                                            value={item.item_text}
                                            onChange={(e) => setItem({ ...item, item_text: e.target.value })}
                                        />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Prix</Form.Label>
                                        <Form.Control
                                            type="number" min='0' step='0.01'
                                            placeholder="Prix de l'objet"
                                            value={item.item_price}
                                            onChange={(e) => setItem({ ...item, item_price: e.target.value })}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Lien</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Lien de l'objet"
                                            value={item.item_link}
                                            onChange={(e) => setItem({ ...item, item_link: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Genre</Form.Label>
                                        <Form.Select
                                            value={item.item_gender}
                                            onChange={(e) => setItem({ ...item, item_gender: e.target.value })}
                                        >
                                            <option>Genre de l'objet</option>
                                            <option value="f">Féminin</option>
                                            <option value="m">Masculin</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Pluralité</Form.Label>
                                        <Form.Select
                                            value={item.item_is_plural}
                                            onChange={(e) => setItem({ ...item, item_is_plural: e.target.value })}
                                        >
                                            <option>Pluralité de l'objet</option>
                                            <option value="0">Singulier</option>
                                            <option value="1">Pluriel</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Déja acheté</Form.Label>
                                        <Form.Select
                                            value={item.item_is_purchased}
                                            onChange={(e) => setItem({ ...item, item_is_purchased: e.target.value })}
                                        >
                                            <option value="0">Non</option>
                                            <option value="1">Oui</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className='d-grid mt-5 mb-4'>
                        <Button type="submit" variant="primary" size="lg">{mode === "edit" ? <><Icon name="pencil" iconStyle="light" className="me-2" />Modifier</> : <><Icon name="plus" iconStyle="light" className="me-2" />Ajouter</>}</Button>
                        {mode === "edit" && <Button type="button" variant="danger" size="lg" className='mt-3' onClick={() => handleDelete()}><Icon name="trash-alt" iconStyle="light" className="me-2" />Supprimer</Button>}
                        <Button type="button" variant="light" size="lg" className='mt-3' onClick={() => navigate("/liste")}><Icon name="chevron-left" iconStyle="light" className="me-2" />Retour</Button>
                    </div>
                </Form>
            </section>
        </>
    )
}

export default ItemForm;
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ReactGA from "react-ga4";

import { ListProvider } from './contexts/ListProvider';

import BirthList from './pages/BirthList';
import ItemsList from './pages/ItemsList';
import ItemForm from './pages/ItemForm';

import AppWrapper from './components/AppWrapper';

import ModalReserveItem from './modals/ModalReserveItem';

import './index.scss';



function App() {


  // useEffect(() => {

  // }, []);

  return (
    <ListProvider>
      <AppWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<BirthList />} />
            <Route path="/liste" element={<ItemsList />} />
            <Route path="/modifier/:id" element={<ItemForm />} />
            <Route path="/ajouter" element={<ItemForm />} />
          </Routes>
        </BrowserRouter>
      </AppWrapper>
      <ModalReserveItem />
    </ListProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

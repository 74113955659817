const API_URL = 'api.guru-worlds.com';
const PASSPORT_NAME = 'passport_birthlist';

export const fetchBirthlist = async (birthlistID) => {
  const API_CALL_URL = API_URL + '/birthlist/' + birthlistID;
  const passport = sessionStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Birthlist data", data.result);
  return data;
};

export const createItem = async (birthlistID, itemData) => {
  const API_CALL_URL = API_URL + '/birthlist/' + birthlistID + '/item';
  const passport = sessionStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(itemData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateItem = async (itemID, itemData) => {
  const API_CALL_URL = API_URL + '/birthlists/item/' + itemID;
  const passport = sessionStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(itemData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateItemOrder = async (itemID, itemData) => {
  const API_CALL_URL = API_URL + '/birthlists/item/' + itemID + '/order';
  const passport = sessionStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(itemData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const deleteItem = async (itemID) => {
  const API_CALL_URL = API_URL + '/birthlists/item/' + itemID;
  const passport = sessionStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'DELETE',
    headers: headers,
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const createItemSponsoring = async (itemID, sponsoringData) => {
  const API_CALL_URL = API_URL + '/birthlists/item/' + itemID + '/sponsoring';
  const passport = sessionStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(sponsoringData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};
import React, { useContext } from 'react';

import ReactGA from "react-ga4";

import { ListContext } from '../contexts/ListProvider';

import ItemBox from '../components/ItemBox';

import { Row, Col } from 'react-bootstrap';

function BirthList() {

    ReactGA.initialize("G-N3V12SE1M0");

    ReactGA.send({ hitType: "pageview", page: "/" });

    const { items } = useContext(ListContext);

    return (
        <>
            <h1 className='text-primary-dark2'>Liste de naissance</h1>
            <p className='lead mb-3 text-primary-dark2'>Bonjour à tous et bienvenue sur la liste de naissance de Louise !</p>
            <p className=''>
                Voici quelques semaines que notre petite Louise a pointé le bout de son nez et ce fut la plus merveilleuse des rencontres 💗.<br />
                Nous avons progressivement commençé à nous équiper pour lui(/nous 😉) offrir un départ dans la vie aussi confortable que possible.
            </p>
            <p>
                Nous avons créé ce site pour ceux qui cherchent des idées de cadeaux à faire à notre petite princesse, qui l’accompagneront dans ses premiers mois de vie.
            </p>
            <p>
                N’hésitez pas à nous contacter si vous avez des questions ou des suggestions en le parcourant.
            </p>
            <p className=''>
                Nous sommes très heureux de vous avoir à nos côtés durant cette merveilleuse étape pour notre famille.
            </p>
            <p>
                Un grand merci à tous pour votre générosité. 🙏
            </p>
            <p className='lead mb-5'>
                Louise est déjà bien entourée ! 😊<br />
                Nous espérons vous voir très bientôt.
            </p>
            <section>
                <Row xs={1} sm={2} lg={3} xl={4} xxl={5}>
                    {items.map((item) => (
                        <Col key={item.id}>
                            <ItemBox item={item} />
                        </Col>
                    ))}
                </Row>
            </section>
            <p className='mt-3'><a href="https://lydia-app.com/collect/louise-2023/fr" target="_blank" className='text-primary-dark2 fw-500'>Vous pouvez également contribuer directement dans notre cagnotte en cliquant ici.</a></p>
            <p className='footer'>
                Avec tout notre amour,<br />
                Lyne-Marine & Julien
            </p>
        </>
    )
}

export default BirthList;
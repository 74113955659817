import React, { useEffect, useContext } from 'react';

import { fetchBirthlist } from '../services/birthlists';
import { askPassport, checkApplication } from '../services/application';
import { ListContext } from '../contexts/ListProvider';

function AppWrapper({ children }) {

    const { setItems } = useContext(ListContext);

    useEffect(() => {
        askPassport().then(() => {
            checkApplication().then((response) => {
                const application = response.result;
                fetchBirthlist('1').then((response) => {
                    console.log(response);
                    setItems(response.result.items);
                });
            });
        });

    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default AppWrapper;
import Icon from "../ui/Icon";

export const formatCurrency = (number) => {
    if (number === undefined) return <></>;
    return <>{number.toString().replace(".", ",")}<Icon name="euro" iconStyle="light" className="ms-1" /></>;
};

export const formatToDecimal = (number) => {
    if (number === undefined) return 0.00;
    return number.toFixed(2);
};
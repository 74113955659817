import React from "react";

function Icon({ name, iconStyle, className }) {

    if(iconStyle === undefined) {
        iconStyle = 'solid';
    }

    if (className) {
        return <i className={`fa-${iconStyle} fa-${name} ${className}`}></i>;
    }

    return <i className={`fa-${iconStyle} fa-${name}`}></i>;
}

export default Icon;
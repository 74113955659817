import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { ListContext } from '../contexts/ListProvider';

import { updateItemOrder, fetchBirthlist } from '../services/birthlists';

import { Button, ButtonGroup, Table, ToastContainer } from 'react-bootstrap';
import { formatCurrency } from '../libs/utils';

import BasicToast from '../ui/Toast';

import Icon from '../ui/Icon';

function ItemsList() {

    const navigate = useNavigate();
    const { items, setItems, lastAction, setLastAction } = useContext(ListContext);

    const [toastAction, setToastAction] = useState([]);

    useEffect(() => {
        let action = lastAction.shift();
        const newAction = (action === "create_item") ? <BasicToast key={Date.now()} title="Objet crée !" message="L'objet à bien été crée" />
            : (action === "update_item") ? <BasicToast key={Date.now()} title="Objet modifié !" message="L'objet à bien été modifié" />
                : (action === "delete_item") ? <BasicToast key={Date.now()} title="Objet supprimé !" message="L'objet à bien été supprimé" />
                    : (action === "update_item_order_") ? <BasicToast key={Date.now()} title="Ordre modifié !" message="L'ordre des objets à bien été modifié" /> : "";
        setToastAction([...toastAction, newAction]);
    }, [lastAction]);

    const handleChangeOrder = (itemID, orderDirection) => {

        const itemData = {
            order_direction: orderDirection
        };

        updateItemOrder(itemID, itemData)
            .then((response) => {
                if (response.status === "Updated") {
                    console.info("✅ Item updated");
                    fetchBirthlist('1').then((response) => {
                        console.log(response);
                        setLastAction([...lastAction, "update_item_order"]);
                        setItems(response.result.items);
                    });
                }
            });



    }

    return (
        <>
            <h1><Link to="/" className='text-primary-dark2'>Liste de naissance</Link></h1>
            <section>
                <div className='d-flex'>
                    <h2 className='text-start'>Liste des objets</h2>
                    <span className='ms-auto'>
                        <Button variant="primary" onClick={() => navigate("/ajouter")}>Ajouter un objet</Button>
                    </span>
                </div>
                <Table bordered className="mt-3">
                    {/* <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.id}>
                                <td style={{ width: 70 }}>{item.index}</td>
                                <td style={{ width: 70, fontSize: '1.4em' }}>{item.emoji}</td>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    <strong>{item.brand}</strong> {item.model}
                                </td>
                                <td style={{ width: 160 }}>{item.price ? formatCurrency(item.price) : ``}</td>
                                <td style={{ width: 60 }}>
                                    {item.link && <a href={item.link} target="_blank"><Icon name="external-link" /></a>}
                                </td>
                                <td style={{ width: 60 }}>
                                    {item.is_reserved === '1' && <><Icon name="check text-primary" /></>}
                                </td>
                                <td>

                                    {item.sponsoring_type === "reserve" && <>
                                        Réservation<br />
                                        <small>{item.reserve_name}</small>
                                    </>}
                                    {item.sponsoring_type === "pay" && <>
                                        Parrainage<br />
                                        <small>{item.reserve_name}</small>
                                    </>}
                                    {item.sponsoring_type === "contribute" && <>
                                        Contribution<br />
                                        {item.is_reserved === '1' ? <small>{item.reserve_name}</small> : <>&nbsp;</>}
                                    </>}
                                    {item.sponsoring_type === "" && <>
                                        <small>&nbsp;</small>
                                    </>}
                                </td>
                                <td style={{ width: 80 }}>
                                    <ButtonGroup>
                                        <Button
                                            size="sm"
                                            variant="outline-primary"
                                            onClick={() => handleChangeOrder(item.id, 'down')}
                                            disabled={item.index <= 1}
                                        >
                                            <Icon name="chevron-up" />
                                        </Button>
                                        <Button
                                            size="sm"
                                            variant="outline-primary"
                                            onClick={() => handleChangeOrder(item.id, 'up')}
                                            disabled={item.index == items.length}
                                        >
                                            <Icon name="chevron-down" />
                                        </Button>
                                    </ButtonGroup>
                                </td>
                                <td style={{ width: 100 }}>
                                    <Button size="sm" variant="primary" onClick={() => navigate("/modifier/" + item.id)}>Modifier</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </section>
            <ToastContainer position="bottom-end" style={{ paddingBottom: 20, paddingRight: 20 }}>
                {toastAction}
            </ToastContainer>
        </>
    )
}

export default ItemsList;
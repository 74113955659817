import React, { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

function UITooltip({ children, content, placement, trigger }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    if (!placement) {
        placement = "right";
    }

    if (!trigger) {
        trigger = "hover";
    }

    if (trigger === "click") {
        return (
            <>
                <span ref={target} onClick={() => setShow(!show)}>
                    {children}
                </span>
                <Overlay target={target.current} show={show} placement={placement}>
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            {content}
                        </Tooltip>
                    )}
                </Overlay>
            </>
        );
    }

    return (
        <>
            <span ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                {children}
            </span>
            <Overlay target={target.current} show={show} placement={placement}>
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
}

export default UITooltip;